import {createRouter, createWebHistory} from 'vue-router'
import {useAuthStore} from '../stores/AuthStore.js'
import TheLoginPage from '../components/auth/TheLoginPage.vue'
import TheUsers from '../components/pages/TheUsers.vue'
import TheUserEdit from '../components/pages/TheUserEdit.vue'
import TheForgotPassword from '../components/auth/TheForgotPassword.vue'
import TheRegistry from '../components/pages/TheRegistry.vue'
import TheRegistryEdit from '../components/pages/TheRegistryEdit.vue'
import TheDocsDirectory from '../components/pages/TheDocsDirectory.vue'
import TheDocsDirectoryEdit from '../components/pages/TheDocsDirectoryEdit.vue'
import TheDeveloperTypesDirectory from '../components/pages/TheDeveloperTypesDirectory.vue'
import TheDeveloperTypesDirectoryEdit from '../components/pages/TheDeveloperTypesEdit.vue'
import TheLogs from '../components/pages/TheLogs.vue';
import TheError from '../components/pages/TheError.vue';
import i18n from "../i18n.js";
import {checkRole} from "../utilits/functions";

const routes = [
    {
        path: '/',
        name: 'Login',
        component: TheLoginPage,
        meta: {
            layout: 'TheExternal',
            header: 'common.enter',
            roles: '*',
        },
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: TheForgotPassword,
        meta: {
            layout: 'TheExternal',
            header: 'common.forgotPassword',
            roles: '*',
        },
    },
    {
        path: '/admin',
        name: 'Dashboard',
        component: TheRegistry,
        meta: {
            layout: 'TheInternal',
            header: 'common.documentFlow',
            breadCrumbs: [
                {
                    title: 'common.documentFlow',
                    linkName: null,
                    current: true
                }
            ],
            roles: '*',
        },
    },
    {
        path: '/admin/registry/edit/:id',
        name: 'RegistryEdit',
        component: TheRegistryEdit,
        meta: {
            layout: 'TheInternal',
            header: 'common.documentFlow',
            breadCrumbs: [
                {
                    title: 'common.documentFlow',
                    linkName: 'Dashboard',
                    current: false
                },
                {
                    title: 'common.documentCard',
                    linkName: null,
                    current: true
                }
            ],
            roles: '*',
        },
    },
    {
        path: '/admin/users',
        name: 'Users',
        component: TheUsers,
        meta: {
            layout: 'TheInternal',
            header: 'common.users',
            breadCrumbs: [
                {
                    title: 'common.users',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin'],
        },
    },
    {
        path: '/admin/users/edit/:id',
        name: 'UserEdit',
        component: TheUserEdit,
        meta: {
            layout: 'TheInternal',
            header: 'common.users',
            breadCrumbs: [
                {
                    title: 'common.users',
                    linkName: 'Users',
                    current: false
                },
                {
                    title: 'common.edit',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin'],
        },
    },
    {
        path: '/admin/directory/docs',
        name: 'DocsDirectory',
        component: TheDocsDirectory,
        meta: {
            layout: 'TheInternal',
            header: 'common.documentType',
            breadCrumbs: [
                {
                    title: 'common.documentType',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'manager'],
        },
    },
    {
        path: '/admin/directory/docs/edit/:id',
        name: 'DocsDirectoryEdit',
        component: TheDocsDirectoryEdit,
        meta: {
            layout: 'TheInternal',
            header: 'common.documentType',
            breadCrumbs: [
                {
                    title: 'common.documentType',
                    linkName: 'DocsDirectory',
                    current: false
                },
                {
                    title: 'common.edit',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'manager'],
        },
    },
    {
        path: '/admin/directory/developer-types',
        name: 'DeveloperTypes',
        component: TheDeveloperTypesDirectory,
        meta: {
            layout: 'TheInternal',
            header: 'common.developerType',
            breadCrumbs: [
                {
                    title: 'common.developerType',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'manager'],
        },
    },
    {
        path: '/admin/directory/developer-types/edit/:id',
        name: 'DeveloperTypesEdit',
        component: TheDeveloperTypesDirectoryEdit,
        meta: {
            layout: 'TheInternal',
            header: 'common.developerType',
            breadCrumbs: [
                {
                    title: 'common.developerType',
                    linkName: 'DeveloperTypes',
                    current: false
                },
                {
                    title: 'common.edit',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin', 'manager'],
        },
    },
    {
        path: '/admin/log',
        name: 'UserLog',
        component: TheLogs,
        meta: {
            layout: 'TheInternal',
            header: 'common.logs',
            breadCrumbs: [
                {
                    title: 'common.logs',
                    linkName: null,
                    current: true
                }
            ],
            roles: ['admin'],
        }
    },
    {
        path: "/:catchAll(.*)",
        name: "NotFound",
        meta: {
            layout: 'TheError',
            header: '404',
        },
        component: TheError,
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes
});

router.beforeEach(async (to, from, next) => {
    const publicPages = ['Login', 'ForgotPassword', 'NotFound'];
    const authRequired = !publicPages.includes(to.name);
    const authStore = useAuthStore();

    if (to.name == 'NotFound'){
        next({name: 'NotFound'})
    }
    document.title = (i18n.global.t(to.meta.header) || 'Личный кабинет') + ' | Россия';


    await authStore.getProfile().then((r) => {
        if (r.data.success === true && r.data.data.active === false) {
            authStore.clearAuthUser();
        }
    }).catch((e) => {
        authStore.clearAuthUser();
    })

    await authStore.getProfile;

    if (import.meta.env.VITE_AUTH_DISABLED) {
        if (localStorage.getItem('authUser') !== null) {
            localStorage.clear()
            window.location.reload()
        }
    }

    if (authRequired && !authStore.authUser) {
        localStorage.setItem('auth_back', window.location.pathname);

        next({name: 'Login'})
    } else if (!authRequired && authStore.authUser) {
        next({name: 'Dashboard'})
    } else {
        if (to.meta.roles !== '*' && !checkRole(to.meta.roles)) {
            next({name: 'Dashboard'})
        }

        next()
    }
})

export default router;